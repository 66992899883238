function ComingSoon() {
  return (
    <div className="relative">
      <div className="pt-4 mx-auto max-w-full sm:px-6 lg:px-3">
        <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
          {/* Hero section wait to add from to for color */}
          <div className="absolute inset-0 ">
            <img
              className="object-contain"
              src={require("../assets/penju.png")}
              alt="penju"
            />
            <div className="absolute inset-0 bg-gradient-to-r" />
          </div>
          {/* need to fix sm hidden text */}
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 ml-96">
            <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-neutral-900 dark:text-white">
                Coming soon!
              </span>
              <span className="block text-custom-yellow pt-10">
                Adventure awaits
              </span>
            </h1>
            {/* <p className="mx-auto mt-6 max-w-lg text-center text-xl text-indigo-200 sm:max-w-3xl">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
                lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
                fugiat aliqua.
              </p> */}
            {/* <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center ">
              <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                <a
                  href="#"
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-indigo-700 shadow-sm hover:bg-indigo-50 sm:px-8"
                >
                  Sign Up
                </a>
                <a
                  href="#"
                  className="flex items-center justify-center rounded-md border border-transparent bg-gray-700 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                >
                  Rent Now
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ComingSoon;
