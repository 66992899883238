import { Disclosure } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [{ name: "Built By Terry", href: "/", current: true }];

function NavBar() {
  return (
    <div className="relative">
      <div className="pt-2 mx-auto max-w-full sm:px-6 lg:px-3">
        <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
          <Disclosure as="nav" className="bg-custom-dark_purple">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      {/** logo */}
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          className="w-15 h-12"
                          src={require("../assets/penju.png")}
                          alt="penju"
                        />
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-custom-purple_blue text-white"
                                  : "text-gray-300 hover:bg-custom-purple_blue hover:text-white",
                                "px-3 py-2 rounded-md text-2xl font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
}
export default NavBar;
